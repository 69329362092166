import Phaser from 'phaser';

export default class BootScene extends Phaser.Scene {
    constructor() {
        super("bootScene");
    };

    enterPressedCount = 0;
    fadeOutTime = 500;

    preload() {
        // loading html form
        this.load.html("loginForm", "html/login-form.html");

        // loading background image
        this.load.image('background', 'assets/images/background.png');

        // loading title images
        this.load.image('titleMax', 'assets/images/title_max.png');
        this.load.image('titleSpace', 'assets/images/title_space.png');

        // loading audio
        this.load.audio('theme', 'assets/music/kirbys-8bit.mp3');
    }

    create() {
        var { width, height } = this.sys.game.canvas;

        // skip login screen if username is set in localstorage (usefull for DEV + live reload)
        if (localStorage.getItem("username")) {
            this.scene.start("gameScene", { 'nickname': localStorage.getItem("username") });
            return;
        }

        // create background
        this.add.image(0, 0, 'background').setOrigin(0, 0).setScale(1, 1);

        // create title
        const titleMaxImage = this.add.image(-500, 250, 'titleMax').setOrigin(0, 0).setScale(0.3, 0.3);
        const titleSpaceImage = this.add.image(width + 500, 250, 'titleSpace').setOrigin(1, 0).setScale(0.3, 0.3);

        // tween title
        this.tweens.add({ targets: titleMaxImage, x: 340, delay: 0, duration: 600, ease: 'Power2' });
        this.tweens.add({ targets: titleSpaceImage, x: width - 340, delay: 0, duration: 600, ease: 'Power2' });

        // create music theme
        const musicTheme = this.sound.add('theme', { volume: 0.5 });
        musicTheme.play();

        // create dom element
        this.dom = this.add.dom(width / 2 - 150, height / 2).createFromCache("loginForm");
        this.nicknameInput = document.getElementById("nickname-input");

        // create copyright text
        this.copyrightText = this.add.text(width / 2, height - 20, "© 2022 Max DS", {
            fontFamily: "pixel-font",
            color: "#fff",
            fontSize: 20,
            fontStyle: "bold"
        }).setOrigin(0.5);
        this.copyrightText.setStroke('#333', 4);

        // create "press enter" text
        this.pressEnterText = this.add.text(width / 2, height / 1.5, "- Press enter -", {
            fontFamily: "pixel-font",
            color: "#fff",
            fontSize: 26
        }).setOrigin(0.5);
        this.pressEnterText.setStroke('#333', 2);
        // make it blink
        this.tweens.add({
            targets: this.pressEnterText,
            alpha: { value: 0, duration: 600, ease: 'Quint.easeInOut' },
            yoyo: true,
            repeat: -1
        });

        // handle "Enter" key press
        this.returnKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);
        this.returnKey.on("down", event => {
            if (this.enterPressedCount == 0) {
                // hide "press enter" text and put focus on nickname input
                this.pressEnterText.visible = false;
                this.nicknameInput.removeAttribute("hidden");
                this.nicknameInput.focus();
                this.enterPressedCount++;
            }
            else if (this.enterPressedCount == 1) {
                // Go to GameScene when "Enter" is pressed and a nickname is present
                if (this.nicknameInput.value != "") {
                    // fade out music
                    this.tweens.add({
                        targets: musicTheme,
                        volume: 0,
                        duration: this.fadeOutTime
                    });
                    // fade out dom
                    this.tweens.add({
                        targets: this.dom,
                        alpha: { value: 0, duration: this.fadeOutTime, ease: 'Linear' },
                        repeat: -1
                    });
                    // fade out view
                    this.cameras.main.fadeOut(this.fadeOutTime, 0, 0, 0);
                    this.enterPressedCount++;
                }
            }
        });

        // handle camera fade out complete event
        this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, (cam, effect) => {
            musicTheme.stop();
            this.scene.start("gameScene", { 'nickname': this.nicknameInput.value });
        })
    }
}