/** Define chat behaviour */

var socket = null;
var form;
var input;
var messages;
var container;

const msgMode = {
    NORMAL: "normal",
    WARNING: "warning",
    SERVER_INFO: "server-info",
    SERVER_IMPORTANT: "server-important"
}

function addMessage(data, mode) {
    var message = document.createElement('div');
    message.classList.add("chat-message");

    if (mode == msgMode.NORMAL) {
        var spanUser = document.createElement('span');
        spanUser.textContent = data.user + ": ";
        spanUser.classList.add('chat-message-user');
        message.appendChild(spanUser);
    }

    var spanMessage = document.createElement('span');
    spanMessage.textContent = data.message;
    message.appendChild(spanMessage);

    if (mode == msgMode.WARNING) {
        spanMessage.classList.add("chat-message-warning");
    }
    else if (mode == msgMode.SERVER_INFO) {
        spanMessage.classList.add("chat-message-server-info");
    }
    else if (mode == msgMode.SERVER_IMPORTANT) {
        spanMessage.classList.add("chat-message-server-important");
    }

    messages.insertBefore(message, messages.childNodes[0]);
}

const init = (s) => {
    socket = s;

    container = document.getElementById('chat-container');
    form = document.getElementById('chat-form');
    input = document.getElementById('chat-input');
    messages = document.getElementById('chat-messages');

    input.addEventListener("keydown", event => {
        if (event.isComposing || event.code === 'Enter') {
            if (input.value) {
                console.log(`[Socket 💬] Sending message : "${input.value}"`);
                socket.emit('chatMessage', input.value);
                input.value = '';
            }
        }

        // je sais pas pourquoi, on peut pas faire "espace" dans le chat
        if (event.isComposing || event.code === 'Space') {
            input.value += " ";
        }
    });

    socket.on('chatMessage', (data) => {
        addMessage(data, data.msgMode ? data.msgMode : msgMode.NORMAL);
    });
}

const hasFocus = () => {
    return input === document.activeElement;
}

const setFocus = (bool) => {
    if (bool) {
        input.focus();
        container.classList.add('focused');
    } else {
        input.blur();
        container.classList.remove('focused');
    }
}

const inputIsEmpty = () => {
    return input.value.length === 0;
}

export const Chat = {
    init,
    hasFocus,
    setFocus,
    inputIsEmpty,
    addMessage,
    msgMode
}