export class DrawingBoard {
  canvas = document.getElementById("canvas");
  userDrawing = document.getElementById("user-drawing");
  ctx = this.canvas.getContext("2d");
  coord = { x: 0, y: 0 };
  socket;
  playerUid;
  canDraw = true;
  oldText = "";
  init = (socket, playerUid) => {
    this.userDrawing.hidden = true
    this.socket = socket;
    this.playerUid = playerUid;
    this.getCanvasStateFromServer();
    this.handleReset();
    let moveToSwitch = 1;
    this.ctx.lineWidth = 5;
    this.ctx.lineCap = "round";
    this.ctx.strokeStyle = "#000000";

    this.socket.on("Stop draw", (state) => {
      if (!moveToSwitch && state.playerUid !== playerUid) {
        this.ctx.beginPath();
        this.ctx.moveTo(state.coord.x, state.coord.y);
        moveToSwitch = 1;
        this.userDrawing.hidden = true;
        this.canDraw = true;
      }
    });
    this.socket.on("Draw", (state) => {
      if (state.playerUid !== playerUid) {
        this.canDraw = false;
        this.userDrawing.hidden = false;
        this.userDrawing.innerText =`${state.playerNickname} est en train de dessiner`;
        if (moveToSwitch) {
          this.ctx.beginPath();
          this.ctx.moveTo(state.coord.x, state.coord.y);
          moveToSwitch = 0;
        }
        this.ctx.lineTo(state.coord.x, state.coord.y);
        this.ctx.stroke();
      }
    });
    this.canvas.addEventListener("mousedown", this.start);
    this.canvas.addEventListener("mouseup", this.stop);
  };

  getCanvasStateFromServer = () => {
    this.socket.emit("Get canvas state");
    this.socket.on("Receive canvas state", (state) => {
      this.drawCanvasFromState(state);
    });
  };

  drawCanvasFromState = (state) => {
    if (state !== "") {
      const image = new Image();
      image.onload = () => {
        this.ctx.drawImage(image, 0, 0);
      };
      image.src = state;
    }
  };

  clearCanvas = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  resetCanvas = () => {
    this.clearCanvas();
    this.socket.emit("Reset canvas", this.playerUid);
  }

  handleReset = () => {
    this.socket.on("Reset canvas", playerUid => {
      if (playerUid !== this.playerUid) {
        this.clearCanvas();
      }
    });
  };

  start = (event) => {
    if (event.which === 1) {
      this.canvas.addEventListener("mousemove", this.draw);
      this.reposition(event);
    }
  };

  reposition = (event) => {
    const scale =
      this.canvas.getBoundingClientRect().width / this.canvas.offsetWidth;
    this.coord.x =
      (event.clientX - this.canvas.getBoundingClientRect().left) / scale;
    this.coord.y =
      (event.clientY - this.canvas.getBoundingClientRect().top) / scale;
  };

  stop = () => {
    this.canvas.removeEventListener("mousemove", this.draw);
    this.socket.emit("Stop draw", {playerUid: this.playerUid, coord: this.coord});
    this.socket.emit("Save canvas state", this.canvas.toDataURL());
  };

  draw = (event) => {
    if (this.canDraw) {
      this.ctx.beginPath();
      this.ctx.moveTo(this.coord.x, this.coord.y);
      this.reposition(event);
      this.ctx.lineTo(this.coord.x, this.coord.y);
      this.socket.emit("Draw", {playerUid: this.playerUid, coord: this.coord});
      this.ctx.stroke();
    }
  };
}
