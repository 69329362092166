import BootScene from './scenes/BootScene';
import GameScene from './scenes/GameScene';
import Phaser from 'phaser'; 

var config = {
    type: Phaser.AUTO,
    parent: 'game-container',
    pixelArt: true,
    
    scale: {
        mode: Phaser.Scale.ScaleModes.FIT,
        parent: 'game-container',
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 1248,
        height: 768
    },
    physics: {
        default: 'arcade',
        arcade: {
            debug: false,
            gravity: { y: 0 }
        }
    },
    dom: {
        createContainer: true
    },
    scene: [BootScene, GameScene]
};

var game = new Phaser.Game(config);