import {Vocal} from "./vocal"; // TODO déplacer le RTC lié au screen-sharing ici (et monter plus haut les fonctions communes)

var socket = null;
var videoDomElement;
var displayMediaStream;
var presentationMode = false;

const init = (s) => {
    socket = s;
    videoDomElement = document.getElementById('screensharing');
    videoDomElement.style.display = "none";

    socket.on('removeScreenSharing', function(data) {
        console.log(`[Socket 🎬] Remove screen sharing, reason: ${data.reason}`);
        closeScreenSharing();
    });

    socket.on('receiveScreenSharing', function (config) {
        console.log('[Socket 🎬] Signaling server send screen sharing from : ', config);
        // ???
    });
}

const toggle = () => {
    if (presentationMode) {
        closeScreenSharing();
        presenterStopScreen();
        presentationMode = false;
    }
    else {
        openScreenSharing();
    }
}

const isPresenting = () => {
    return presentationMode;
}

const openScreenSharing = () => {
    console.log("[Game 🎬] Opening screen sharing");
    videoDomElement.style.display = "inline-block";
    displayMediaStream = navigator.mediaDevices.getDisplayMedia({ video: true });
    displayMediaStream
        .then(function (stream) {
            videoDomElement.srcObject = stream;
            Vocal.presenterAddScreen(stream);
            presentationMode = true;
        })
        .catch(function (err) {
            console.error(`[Game 🎬] Could not acquire media stream (${err.name} : ${err.message})`);
        });
}

const closeScreenSharing = () => {
    const stream = videoDomElement.srcObject;
    if (stream) {
        console.log("[Game 🎬] Closing screen sharing");
        stream.getTracks().forEach(function (track) {
            track.stop(); //close screen sharing stream
        });
        videoDomElement.srcObject = null; // remove the stream object from dom to clean
        videoDomElement.style.display = "none"; // hide the white board
    }
}

const presenterStopScreen = () => {
    socket.emit('presenterSendStopScreenSharing', {});
}

export const ScreenSharing = {
    init,
    toggle,
    isPresenting,
    closeScreenSharing
}