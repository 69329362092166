import io from "socket.io-client";
import { RenderDepth } from "../renderDepth";
import { Vocal } from "../vocal";
import { Chat } from "../chat";
import { DrawingBoard } from "../drawing-board";
import { ScreenSharing } from "../screenSharing";

export default class GameScene extends Phaser.Scene {
  defaultRoom = "entree";
  spawnPoint;
  playersWalkingSpeed = 160;
  playersRunningSpeed = 240;
  playersSpeed = this.playersWalkingSpeed;
  frequencySentPosition = 100; // in milliseconds, we sent player's position every XXX ms to the nodejs server to reduce load
  playerNickname;
  playerUID;
  doorways = [];
  currentRoomText;
  lastTimeStamp = 0;
  // Array<Object> ex: [{nickname: "toto", socketId: "01234", current: true}, {nickname: "titi", socketId: "56789", current: false}]
  playersList = [];
  microphoneIsOn = true;
  musicIsOn = true;
  musicVolume = 0.1;
  camera;

  displayMediaStream;

  constructor() {
    super("gameScene");
  }

  init(data) {
    this.playerNickname = data.nickname ? data.nickname : "NONAME";
    this.playerUID = "_" + Math.random().toString(36).substr(2, 9);
  }

  preload() {
    var { width, height } = this.sys.game.canvas;

    // loading connected player list html
    this.load.html("screenSharing", "html/screen-sharing.html");
    // loading connected player list html
    this.load.html("connectedPlayers", "html/connected-players.html");
    // loading chat html
    this.load.html("chat", "html/chat.html");
    // loading drawing board html
    this.load.html("drawing-board", "html/drawing-board.html");
    // loading button to toggle drawing board html
    this.load.html("drawing-board-button", "html/drawing-board-button.html");

    // loading characters assets
    this.load.spritesheet("player", "assets/images/characters/player.png", {
      frameWidth: 32,
      frameHeight: 64,
    });
    this.load.spritesheet("others", "assets/images/characters/others.png", {
      frameWidth: 32,
      frameHeight: 64,
    });

    // loading background image
    this.load.image("background", "assets/images/background.png");
    // loading "disconnected" image
    this.load.image("disconnected", "assets/images/disconnected.png");
    // loading "muted mic" image
    this.load.image("mutedMic", "assets/images/muted_mic.png");
    // loading "muted music" image
    this.load.image("mutedMusic", "assets/images/muted_music.png");

    // loading map assets
    this.load.image("tiles_rooms", "assets/tilesets/rooms_48x48.png");
    this.load.image("tiles_items", "assets/tilesets/items_48x48.png");

    // loading the exported Tiled JSON
    this.load.tilemapTiledJSON("map", "assets/tilemaps/locaux_v3.json");

    // loading audio
    this.load.audio("main-theme", "assets/music/tarrey-town-8-bit-botw.mp3");

    // create loading progress bar
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(width / 2 - 170, height / 2 - 10, 320, 50);

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: "Loading...",
      style: {
        font: "20px pixel-font",
        fill: "#ffffff",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 + 15,
      text: "0%",
      style: {
        font: "18px pixel-font",
        fill: "#ffffff",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRect(width / 2 - 160, height / 2, 300 * value, 30);
      percentText.setText(parseInt(value * 100) + "%");
    });
    this.load.on("fileprogress", function (file) {});
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
    });
  }

  create() {
    var { width, height } = this.sys.game.canvas;
    var self = this;

    // create current room text
    this.currentRoomText = this.add.text(10, 10, this.defaultRoom, {
      fontFamily: "pixel-font",
      fontSize: "30px",
      color: "#fff",
    });
    this.currentRoomText.setShadow(0, 0, "#333333", 2, true, true);
    this.currentRoomText.setStroke("#333", 6);
    this.currentRoomText.setDepth(RenderDepth.GAME_UI);
    this.currentRoomText.scrollFactorX = 0;
    this.currentRoomText.scrollFactorY = 0;

    // create "connected players" dom element
    this.connectedPlayersDom = this.add
      .dom(width - 300, 20)
      .createFromCache("connectedPlayers");
    this.connectedPlayersDom.scrollFactorX = 0;
    this.connectedPlayersDom.scrollFactorY = 0;

    // create "chat" dom element
    this.chatDom = this.add
      .dom(0, height)
      .createFromCache("chat")
      .setOrigin(0, 1);
    this.chatDom.scrollFactorX = 0;
    this.chatDom.scrollFactorY = 0;

    // create "drawing-board" dom element
    this.drawingBoardDom = this.add
      .dom(width / 2, height / 2)
      .createFromCache("drawing-board");
    this.drawingBoardDom.scrollFactorX = 0;
    this.drawingBoardDom.scrollFactorY = 0;
    this.drawingBoardDom.visible = false;

    // create "drawing-board-button" dom element
    this.drawingBoardButtonDom = this.add
      .dom(width - 20 , height - 20)
      .setOrigin(1,1)
      .createFromCache("drawing-board-button");
    this.drawingBoardButtonDom.scrollFactorX = 0;
    this.drawingBoardButtonDom.scrollFactorY = 0;
    this.drawingBoardButtonDom.setInteractive();
    this.drawingBoardButtonDom.addListener('click');
    this.drawingBoardButtonDom.on('click', this.toggleDrawingBoard)

    // create "screensharing" dom element
    // DEBUG MODE (sharescreen dans l'entrée)
    // this.screenSharingDom = this.add.dom(1240, 800,this.defaultRoom).createFromCache("screenSharing");
    // NORMAL MODE (sharescreen dans l'amphi)
    this.screenSharingDom = this.add
      .dom(2400, 1150)
      .createFromCache("screenSharing");

    // create "disconnected" image and tween (img is pulsing)
    this.disconnectedImage = this.add
      .image(width / 2, 20, "disconnected")
      .setOrigin(0.5, 0);
    this.disconnectedImage.setDepth(RenderDepth.GLOBAL_UI);
    this.disconnectedImage.scrollFactorX = 0;
    this.disconnectedImage.scrollFactorY = 0;
    this.disconnectedImage.alpha = 0.5;
    this.disconnectedImage.visible = false;
    this.disconnectedImageTween = this.tweens.add({
      targets: this.disconnectedImage,
      alpha: { value: 0.9, duration: 500, ease: "Bounce.easeInOut" },
      repeat: -1,
      yoyo: true,
    });

    // create "muted mic" image
    this.mutedMicImage = this.add
      .image(width - 20, height - 20, "mutedMic")
      .setOrigin(1, 1);
    this.mutedMicImage.setScale(0.5);
    this.mutedMicImage.setDepth(RenderDepth.GLOBAL_UI);
    this.mutedMicImage.scrollFactorX = 0;
    this.mutedMicImage.scrollFactorY = 0;
    this.mutedMicImage.visible = false;

    // create "muted music" image
    this.mutedMusicImage = this.add
      .image(width - 120, height - 20, "mutedMusic")
      .setOrigin(1, 1);
    this.mutedMusicImage.setScale(0.5);
    this.mutedMusicImage.setDepth(RenderDepth.GLOBAL_UI);
    this.mutedMusicImage.scrollFactorX = 0;
    this.mutedMusicImage.scrollFactorY = 0;
    this.mutedMusicImage.visible = false;

    // create background
    var backgroundImage = this.add.image(0, 0, "background").setOrigin(0, 0);
    backgroundImage.setScale(2, 2);

    // create map from tiles
    const map = this.make.tilemap({ key: "map" });

    // retrieve spawn point
    this.spawnPoint = map.findObject(
      "Objects",
      (obj) => obj.name === "spawnPoint"
    );

    // create doorways
    var doorwaysObjects = map
      .getObjectLayer("Objects")
      .objects.filter((obj) => obj.name !== "spawnPoint");
    doorwaysObjects.forEach((e) => {
      var r = new Phaser.Geom.Rectangle(e.x, e.y, e.width, e.height);
      r.name = e.name;
      r.roomName = e.properties.find((p) => p.name === "roomName").value;
      this.doorways.push(r);
    });

    // set camera bounds and initial position
    this.camera = this.cameras.main;
    this.camera.setBounds(0, 0, map.widthInPixels, map.heightInPixels);
    this.camera.centerOn(this.spawnPoint.x, this.spawnPoint.y);

    // create map layers
    const tileset_rooms = map.addTilesetImage("rooms", "tiles_rooms");
    const tileset_items = map.addTilesetImage("items", "tiles_items");

    map.createLayer("Ground", tileset_rooms, 0, 0);
    const walls = map.createLayer("Walls", tileset_rooms, 0, 0);

    const wallsAbovePlayer = map.createLayer(
      "WallsAbovePlayer",
      tileset_rooms,
      0,
      0
    );
    wallsAbovePlayer.setDepth(RenderDepth.TILES_WALLS_ABOVE_PLAYER);
    const wallsAboveAll = map.createLayer("WallsAboveAll", tileset_rooms, 0, 0);
    wallsAboveAll.setDepth(RenderDepth.TILES_WALLS_ABOVE_ALL);
    const items = map.createLayer("Items", tileset_items, 0, 0);
    items.setDepth(RenderDepth.TILES_ITEMS);
    const itemsAboveaAll = map.createLayer(
      "ItemsAboveAll",
      tileset_items,
      0,
      0
    );
    itemsAboveaAll.setDepth(RenderDepth.TILES_ITEMS_ABOVE_ALL);
    const furnituresUnderFurnitures = map.createLayer(
      "FurnituresUnderFurnitures",
      tileset_items,
      0,
      0
    );
    const furnitures = map.createLayer("Furnitures", tileset_items, 0, 0);
    const furnituresAbovePlayer = map.createLayer(
      "FurnituresAbovePlayer",
      tileset_items,
      0,
      0
    );
    furnituresAbovePlayer.setDepth(RenderDepth.TILES_FURNITURES_ABOVE_PLAYER);

    // set collisions with specific layers
    walls.setCollisionByExclusion(-1, true);
    furnitures.setCollisionByProperty({ collides: true });
    furnituresUnderFurnitures.setCollisionByProperty({ collides: true });
    items.setCollisionByProperty({ collides: true });

    this.collideWithPlayer = [
      walls,
      furnitures,
      furnituresUnderFurnitures,
      items,
    ];

    this.otherPlayers = this.physics.add.group();

    // create character animations
    this.createAnimations("player");
    this.createAnimations("others");

    // create debug graphics
    this.debugGraphics = this.add.graphics();
    this.debugGraphics.visible = false;
    this.debugGraphics.setDepth(RenderDepth.DEBUG_UI);

    // define WebSocket behaviour
    this.socket = io({
      query: {
        nickname: this.playerNickname,
        uid: this.playerUID,
      },
    });

    this.socket.on("disconnect", function (reason) {
      console.log("[Socket 🎮] Server connection lost : " + reason);
      self.disconnectedImage.visible = true;
    });

    this.socket.io.on("reconnect", () => {
      console.log("[Socket 🎮] Reconnected to server !");
      this.socket.emit("playerReconnect", {});

      self.disconnectedImage.visible = false;

      this.character.nickname.destroy();
      this.character.destroy();
      this.character = undefined;

      this.otherPlayers.getChildren().forEach((p) => {
        p.nickname.destroy();
        p.destroy();
        p = undefined;
      });

      this.playersList = [];
    });

    this.socket.on("currentPlayers", function (players) {
      console.log(
        "[Socket 🎮] Currently connected players : " + JSON.stringify(players)
      );
      Object.keys(players).forEach(function (id) {
        if (players[id].socketId === self.socket.id) {
          self.addMainPlayer(players[id]);
        } else {
          self.addOtherPlayer(players[id]);
        }
        self.playersList.push({
          nickname: players[id].nickname,
          socketId: players[id].socketId,
          current: players[id].socketId === self.socket.id,
        });
      });
      self.updatePlayersList();
    });

    this.socket.on("newPlayer", function (playerInfo) {
      console.log(`[Socket 🎮] new player <${playerInfo.nickname}> connected`);
      self.playersList.push({
        nickname: playerInfo.nickname,
        socketId: playerInfo.socketId,
        current: false,
      });
      self.addOtherPlayer(playerInfo);
      self.updatePlayersList();
    });

    this.socket.on("playerDisconnect", function (data) {
      console.log(`[Socket 🎮] Player <${data.nickname}> disconnected`);
      self.otherPlayers.getChildren().forEach(function (otherPlayer) {
        if (data.socketId === otherPlayer.socketId) {
          otherPlayer.nickname.destroy();
          otherPlayer.destroy();
          self.playersList = self.playersList.filter(
            (p) => p.socketId !== data.socketId
          );
        }
      });
      self.updatePlayersList();
    });

    this.socket.on("playerMoved", function (playerInfo) {
      self.otherPlayers.getChildren().forEach(function (otherPlayer) {
        if (playerInfo.socketId === otherPlayer.socketId) {
          // update position
          //  otherPlayer.setPosition(playerInfo.x, playerInfo.y);
          self.tweens.add({
            targets: otherPlayer,
            duration: self.frequencySentPosition,
            x: playerInfo.x,
            y: playerInfo.y,
            ease: "Power2",
          });
          // update room if new
          if (otherPlayer.room !== playerInfo.room) {
            otherPlayer.previousRoom = otherPlayer.room;
            otherPlayer.room = playerInfo.room;
          }
          // update animation
          otherPlayer.play(
            `others_${playerInfo.animation}_${playerInfo.direction}`,
            true
          );
        }
      });
    });

    this.cursors = this.input.keyboard.createCursorKeys();
    this.cursors.keyz = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.Z,
      false
    );
    this.cursors.keyq = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.Q,
      false
    );
    this.cursors.keys = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.S,
      false
    );
    this.cursors.keyd = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.D,
      false
    );
    this.cursors.keyp = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.P,
      false
    );
    this.cursors.keym = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.M,
      false
    );
    this.cursors.keyo = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.O,
      false
    );
    this.cursors.keyl = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.L,
      false
    );
    this.cursors.keyk = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.K,
      false
    );
    this.cursors.space = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.SPACE,
      false
    );
    this.cursors.enter = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.ENTER,
      false
    );
    this.cursors.escape = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.ESC,
      false
    );
    this.cursors.shift = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.SHIFT,
      false
    );

    // init auxiliary systems
    Vocal.init(this.socket, this.playerNickname);
    Chat.init(this.socket);
    this.drawingBoard = new DrawingBoard();
    this.drawingBoard.init(this.socket, this.playerUID);
    ScreenSharing.init(this.socket);

    // create and fade in music theme
    this.musicTheme = this.sound.add("main-theme", { volume: 0 });
    this.musicTheme.play();
    this.tweens.add({
      targets: this.musicTheme,
      volume: this.musicVolume,
      duration: 3000,
    });

    // fade in the view !
    this.camera.fadeIn(500, 0, 0, 0);
  }

  createAnimations(spritesheet) {
    const walk = [
      { direction: "right", range: { start: 48, end: 53 } },
      { direction: "up", range: { start: 54, end: 59 } },
      { direction: "left", range: { start: 60, end: 65 } },
      { direction: "down", range: { start: 66, end: 71 } },
    ].forEach((a) => {
      this.anims.create({
        key: `${spritesheet}_walk_${a.direction}`,
        frames: this.anims.generateFrameNumbers(spritesheet, a.range),
        frameRate: 8,
        repeat: -1,
      });
    });

    const idle = [
      { direction: "right", value: 0 },
      { direction: "up", value: 1 },
      { direction: "left", value: 2 },
      { direction: "down", value: 3 },
    ].forEach((a) => {
      this.anims.create({
        key: `${spritesheet}_idle_${a.direction}`,
        frames: this.anims.generateFrameNumbers(spritesheet, {
          frames: [a.value],
        }),
        frameRate: 1,
      });
    });
  }

  addMainPlayer(playerInfo) {
    this.character = this.createPlayer("player", playerInfo, this.defaultRoom);
    // Set collisions
    this.collideWithPlayer.forEach((x) => {
      this.physics.add.collider(this.character, x);
    });
    // Set camera to follow player
    this.camera.startFollow(this.character, true, 0.1, 0.1);
  }

  addOtherPlayer(playerInfo) {
    var otherPlayer = this.createPlayer("others", playerInfo, playerInfo.room);

    // Play proper animation
    otherPlayer.play(
      `others_${playerInfo.animation}_${playerInfo.direction}`,
      true
    );
    this.otherPlayers.add(otherPlayer);
  }

  createPlayer(spriteName, playerInfo, room) {
    if (playerInfo.x == 0 && playerInfo.y == 0) {
      playerInfo.x = this.spawnPoint.x;
      playerInfo.y = this.spawnPoint.y;
    }

    var player = this.physics.add
      .sprite(playerInfo.x, playerInfo.y, spriteName)
      .setOrigin(0.5, 0.5);
    player.socketId = playerInfo.socketId;
    player.body.setSize(32, 24);
    player.body.setOffset(0, 40);
    player.nickname = this.createNicknameText(playerInfo.nickname);
    player.room = room;
    return player;
  }

  createNicknameText(content) {
    var text = this.add.text(0, 0, `<${content}>`, {
      fontFamily: "pixel-font",
      fontSize: "14px",
      color: "#fff",
    });
    text.setShadow(2, 2, "#333333", 2, true, true);
    text.setDepth(RenderDepth.GAME_UI);
    return text;
  }

  update(timestamp, delta) {
    // Update player
    if (this.character) {
      var movementX = 0;
      var movementY = 0;
      var direction = this.character.direction
        ? this.character.direction
        : "down";
      var animation = this.character.animation
        ? this.character.animation
        : "idle";

      if (!Chat.hasFocus()) {
        if (this.cursors.left.isDown || this.cursors.keyq.isDown) {
          movementX = -1;
          direction = "left";
        }
        if (this.cursors.right.isDown || this.cursors.keyd.isDown) {
          movementX = 1;
          direction = "right";
        }
        if (this.cursors.up.isDown || this.cursors.keyz.isDown) {
          movementY = -1;
          direction = "up";
        }
        if (this.cursors.down.isDown || this.cursors.keys.isDown) {
          movementY = 1;
          direction = "down";
        }
        if (movementX == 0 && movementY == 0) {
          animation = "idle";
        } else {
          animation = "walk";
        }
      }

      // update animation and velocity
      this.character.play(`player_${animation}_${direction}`, true);
      this.character.setVelocityX(movementX * this.playersSpeed);
      this.character.setVelocityY(movementY * this.playersSpeed);
      this.character.body.velocity.normalize().scale(this.playersSpeed);
      this.character.direction = direction;

      // update nickname position
      this.updateCharacterNicknamePosition(this.character);

      var x = this.character.x;
      var y = this.character.y;

      // emit player room (if it changed)
      if (
        this.character.oldPosition &&
        (x !== this.character.oldPosition.x ||
          y !== this.character.oldPosition.y)
      ) {
        this.doorways.forEach((d) => {
          if (
            d.contains(
              this.character.body.center.x,
              this.character.body.center.y
            )
          ) {
            if (this.character.room !== d.roomName) {
              this.character.previousRoom = this.character.room;
              this.character.room = d.roomName;
              this.currentRoomText.text = d.roomName;

              console.log(
                `[Game 🎮] Changed room : ${this.character.room} (previous : ${this.character.previousRoom})`
              );
              Vocal.changeRoom(
                this.character.previousRoom,
                this.character.room,
                this.playerNickname
              );

              //if player leave amphi and was the presenter, close screen sharing
              if (
                ScreenSharing.isPresenting &&
                this.character.previousRoom === "amphi"
              ) {
                ScreenSharing.closeScreenSharing();
              }
            }
          }
        });
      }

      // emit player movement
      // in order to limit traffic in websocket, we sent player's positions only each 'frequencySentPosition' ms
      if (this.lastTimeStamp + this.frequencySentPosition < timestamp) {
        this.lastTimeStamp = timestamp;
        //if we'd never sent a position, sent first
        //or if the last sent' position was different, sent the new one
        if (
          !this.character.sentPosition ||
          x !== this.character.sentPosition.x ||
          y !== this.character.sentPosition.y ||
          animation !== this.character.sentPosition.animation
        ) {
          this.socket.emit("playerMovement", {
            x: this.character.x,
            y: this.character.y,
            room: this.character.room,
            animation: animation,
            direction: direction,
          });
          // save last sent position data
          this.character.sentPosition = {
            x: this.character.x,
            y: this.character.y,
            animation: this.animation,
          };
        }
      }

      // save old position data
      this.character.oldPosition = {
        x: this.character.x,
        y: this.character.y,
      };
    }

    // Update other players
    if (this.otherPlayers) {
      this.otherPlayers.getChildren().forEach(function (p) {
        this.updateCharacterNicknamePosition(p);
      }, this);
    }

    // Handle inputs
    if (!Chat.hasFocus()) {
      // Check for debug key-press
      if (Phaser.Input.Keyboard.JustDown(this.cursors.space)) {
        this.toggleDebugGraphics();
        this.socket.emit("debug");
      }

      // Check for running key
      if (Phaser.Input.Keyboard.JustDown(this.cursors.shift)) {
        this.playersSpeed = this.playersRunningSpeed;
      }
      if (Phaser.Input.Keyboard.JustUp(this.cursors.shift)) {
        this.playersSpeed = this.playersWalkingSpeed;
      }

      // Toggle microphone
      if (Phaser.Input.Keyboard.JustDown(this.cursors.keym)) {
        this.toggleMicrophone();
      }

      // Toggle music theme
      if (Phaser.Input.Keyboard.JustDown(this.cursors.keyo)) {
        this.toggleMusicVolume();
      }

      // Set focus on chat input
      if (Phaser.Input.Keyboard.JustDown(this.cursors.enter)) {
        Chat.setFocus(true);
      }

      if (Phaser.Input.Keyboard.JustDown(this.cursors.keyl)) {
        this.toggleDrawingBoard();
      }

      if (Phaser.Input.Keyboard.JustDown(this.cursors.keyk)) {
        this.drawingBoard.resetCanvas();
      }

      const resetBoardButton = this.drawingBoardDom.getChildByID("reset-board");
      resetBoardButton.addEventListener('click', this.drawingBoard.resetCanvas);

      // Toggle screen-sharing
      // TODO désactivé tant qu'on aura pas réparé le fait que ça pète en parti l'audio et que
      // ça ne marche qu'à 2
      // if (Phaser.Input.Keyboard.JustDown(this.cursors.keyp)) {
      //     this.toggleScreenSharing();
      // }
    } else {
      // Remove focus from chat input
      if (
        Phaser.Input.Keyboard.JustDown(this.cursors.escape) ||
        (Phaser.Input.Keyboard.JustDown(this.cursors.enter) &&
          Chat.inputIsEmpty())
      ) {
        Chat.setFocus(false);
      }
    }
  }

  toggleDrawingBoard = () => {
    console.log("yo")
    this.drawingBoardDom.visible = !this.drawingBoardDom.visible;
  };

  /**
   * Update the character nickname position
   * You need to substract half of body.width "(character.body.width / 2)" ONLY if body.width != character.width
   */
  updateCharacterNicknamePosition(character) {
    character.nickname.x =
      character.body.position.x -
      character.nickname.width / 2 +
      character.width / 2;
    character.nickname.y = character.body.position.y - character.height + 5;
  }

  /**
   * Update DOM players list
   */
  updatePlayersList() {
    document.getElementById("players").innerHTML = `Connected players 
        <ul>
            ${this.playersList
              .map(
                (player) =>
                  `<li class="player${player.current ? " me" : " other"}">${
                    player.nickname
                  }</li>`
              )
              .join("\n")}
        </ul>`;
  }

  /**
   * Mute microphone if not already muted. Unmute otherwise.
   */
  toggleMicrophone() {
    if (Vocal.isMediaAllowed()) {
      this.microphoneIsOn = !this.microphoneIsOn;
      Vocal.toggleMicrophone(this.microphoneIsOn);
      this.mutedMicImage.visible = !this.microphoneIsOn;
    } else {
      Chat.addMessage(
        {
          message:
            "⚠️ Tu ne peux pas mute/unmute ton micro si tu ne l'as pas activé !",
        },
        Chat.msgMode.WARNING
      );
      console.log(
        "[Game 🎮] You can't toggle your mic if you didn't activate your mic 🤡"
      );
    }
  }

  /**
   * Lower music volume to 0 if not already. Set it back otherwise.
   */
  toggleMusicVolume() {
    if (this.musicIsOn) {
      this.musicTheme.volume = 0;
    } else {
      this.musicTheme.volume = this.musicVolume;
    }
    this.musicIsOn = !this.musicIsOn;
    this.mutedMusicImage.visible = !this.musicIsOn;
  }

  /**
   * If in the correct room : opens screen-sharing if not already opened, close it otherwise.
   * If not : do nothing and print a warning message in chat
   */
  toggleScreenSharing() {
    if (this.character.room == "amphi") {
      ScreenSharing.toggle();
    } else {
      Chat.addMessage(
        { message: "⚠️ Tu dois être dans l'amphi pour présenter ton écran" },
        Chat.msgMode.WARNING
      );
      console.log(
        "[Game 🎮] You can't open screen-sharing outside the 'amphi' room"
      );
    }
  }

  /**
   * Draw debug graphics (colliders, AoE, objects, spawn points, etc)
   */
  toggleDebugGraphics() {
    if (this.debugGraphics.visible) {
      console.log("[Game 🎮] Toggle DEBUG 🦋 : OFF");
      this.debugGraphics.clear();
      this.debugGraphics.visible = false;
    } else {
      console.log("[Game 🎮] Toggle DEBUG 🐛 : ON");
      this.debugGraphics.visible = true;

      // Draw doorways
      this.debugGraphics.fillStyle("0xFF0000", 0.5);
      this.doorways.forEach((d) => {
        this.debugGraphics.fillRectShape(d);
      });

      // Draw spawn point
      this.debugGraphics.fillStyle("0xFFFF00", 0.5);
      this.debugGraphics.fillCircle(this.spawnPoint.x, this.spawnPoint.y, 20);

      // Draw colliding tiles
      this.collideWithPlayer.forEach((layer) => {
        layer.renderDebug(this.debugGraphics, {
          tileColor: null,
          collidingTileColor: new Phaser.Display.Color(243, 134, 48, 128),
          faceColor: new Phaser.Display.Color(40, 39, 37, 200),
        });
      });
    }
  }
}
