/** CONFIG **/
var USE_AUDIO = true;
var USE_VIDEO = false;
var DEFAULT_CHANNEL = 'entree';
var MUTE_AUDIO_BY_DEFAULT = false;
var MEDIA_ALLOWED = false; /* si oui on non l'utilisateur a donné l'acces à son micro & sa camera */

/** You should probably use a different stun server doing commerppa:gnome-terminator/ppacial stuff **/
/** Also see: https://gist.github.com/zziuni/3741933 **/
var ICE_SERVERS = [
    { url: "stun:stun.l.google.com:19302" }
];

var socket = null;             /* our socket.io connection to our webserver */
var local_media_stream = null; /* our own microphone / webcam */
var local_screensharing_stream = null; /* our own screen sharing */
var peers = {};                /* keep track of our peer connections, indexed by peer_id (aka socket.io id) */
var peer_media_elements = {};  /* keep track of our <video>/<audio> tags, indexed by peer_id */

function joinChannel(channel, nickname) {
    socket.emit('join', { "channel": channel, "nickname": nickname });
}

function leaveChannel(channel, nickname) {
    socket.emit('part', { "channel": channel, "nickname": nickname });
}

function init(s, playerNickname) {
    console.log("[Socket 🔊] Connecting to signaling server...");
    socket = s;
    socket.playerNickname = playerNickname;

    socket.on('connect', function () {
        console.log("[Socket 🔊] Connected to signaling server");
        setup_local_media().then(() => {
            // once the user has given us access to their microphone/camcorder
            // join the channel and start peering up
            joinChannel(DEFAULT_CHANNEL, playerNickname);
        });
    });

    socket.on('disconnect', function () {
        console.log("[Socket 🔊] Disconnected from signaling server");
        /* Tear down all of our peer connections and remove all the media divs when we disconnect */
        for (peer_id in peer_media_elements) {
            peer_media_elements[peer_id].remove();
        }
        for (peer_id in peers) {
            peers[peer_id].close();
        }

        peers = {};
        peer_media_elements = {};
    });

    /** 
    * When we join a group, our signaling server will send out 'addPeer' events to each pair of users in the group
    * (creating a fully-connected graph of users, ie. if there are 6 people in the channel you will connect directly
    * to the other 5, so there will be a total of 15 connections in the network). 
    */
    socket.on('addPeer', function (config) {
        console.log('[Socket 🔊] Signaling server said to add peer: ', config);
        var peer_id = config.peer_id;
        if (peer_id in peers) {
            /* This could happen if the user joins multiple channels where the other peer is also in. */
            console.log("[Socket 🔊] Already connected to peer ", peer_id);
            return;
        }

        var peer_connection = new RTCPeerConnection(
            { "iceServers": ICE_SERVERS },
            {
                /* this will no longer be needed by chrome
                 * eventually (supposedly), but is necessary 
                 * for now to get firefox to talk to chrome */
                "optional": [{ "DtlsSrtpKeyAgreement": true }]
            }
        );

        peers[peer_id] = peer_connection;

        peer_connection.onicecandidate = function (event) {
            if (event.candidate) {
                socket.emit('relayICECandidate', {
                    'peer_id': peer_id,
                    'ice_candidate': {
                        'sdpMLineIndex': event.candidate.sdpMLineIndex,
                        'candidate': event.candidate.candidate
                    }
                });
            }
        }

        // peer_connection.onnegotiationneeded = async () => {
        //     await peer_connection.setLocalDescription(await peer_connection.createOffer());
        //     socket.emit('relaySessionDescription', {
        //         peer_id: peer_id,
        //         session_description: peer_connection.localDescription
        //     });
        // }

        socket.on('relaySessionDescription', function (config) {
            var peer_id = config.peer_id;
            var session_description = config.session_description;
    
            if (peer_id in sockets) {
                console.log(`[Socket 🔊 ${socket.id}] User <${socket.playerNickname}> relaying session description to <${sockets[peer_id].playerNickname}>(${peer_id})`);
                // console.log(`[Socket 🔊 ${socket.id}] Session description : `, session_description);
                sockets[peer_id].emit('sessionDescription', { 'peer_id': socket.id, 'session_description': session_description });
            }
        });

        peer_connection.ontrack = function (rtcTrackEvent) {
            console.log("[Socket 🎬] ontrack, rtcTrackEvent=", rtcTrackEvent);
            rtcTrackEvent.streams.forEach(stream => {
                console.log("[Socket 🎬] ontrack, stream.id=", stream.id);
                //if this is screensharing
                if (stream.getVideoTracks().length > 0) {
                    var screen = document.getElementById("screensharing");
                    screen.style.display = 'inline-block';
                    screen.muted = true;
                    screen.srcObject = stream;
                } else {
                    var remote_media = USE_VIDEO ? $("<video>") : $("<audio>");
                    remote_media.attr("autoplay", "autoplay");
                    if (MUTE_AUDIO_BY_DEFAULT) {
                        remote_media.attr("muted", "true");
                    }
                    remote_media.attr("style", "display:none");
                    peer_media_elements[peer_id] = remote_media;
                    $('body').append(remote_media);
                    remote_media[0].srcObject = stream;
                }
            });
        }

        /* Add our local stream */
        local_media_stream.getTracks().forEach(track => {
            peer_connection.addTrack(track, local_media_stream)
        });

        /* Only one side of the peer connection should create the
         * offer, the signaling server picks one to be the offerer. 
         * The other user will get a 'sessionDescription' event and will
         * create an offer, then send back an answer 'sessionDescription' to us
         */
        if (config.should_create_offer) {

            if (local_screensharing_stream) {
                const screen = local_screensharing_stream.getTracks()[0];
                socket.emit('addScreenSharing', { "socketid": socket.id, "nickname": socket.playerNickname });

                for (peer_id in peers) {
                    peers[peer_id].getSenders().forEach((sender) => {
                        if (sender.track == null) {
                            sender.replaceTrack(screen);
                        }
                        else if (sender.track.kind === "video") {
                            sender.replaceTrack(screen);
                        }
                        // else {
                        //     peers[peer_id].addTrack(screen, local_screensharing_stream);
                        // }
                    });
                }
            }

            console.log("Creating RTC offer to ", peer_id);
            peer_connection.createOffer(
                function (local_description) {
                    // console.log("Local offer description is: ", local_description);
                    peer_connection.setLocalDescription(local_description,
                        function () {
                            socket.emit('relaySessionDescription', {
                                'peer_id': peer_id,
                                'session_description': local_description
                            });
                            console.log("Offer setLocalDescription succeeded");
                        },
                        function () { Alert("Offer setLocalDescription failed!"); }
                    );
                },
                function (error) {
                    console.log("Error sending offer: ", error);
                }
            );
        }
    });

    /** 
     * Peers exchange session descriptions which contains information
     * about their audio / video settings and that sort of stuff. First
     * the 'offerer' sends a description to the 'answerer' (with type
     * "offer"), then the answerer sends one back (with type "answer").  
     */
    socket.on('sessionDescription', function (config) {
        //console.log('[Socket 🔊] Remote description received: ', config);
        console.log(`[Socket 🔊] Remote description received from [${config.peer_id}]`);

        var peer_id = config.peer_id;
        var remote_description = config.session_description;
        var peer = peers[peer_id];
        if(peer === undefined) {
            console.warn(`[Socket 🔊] Peer [${peer_id}] was not found among peers`);
            return;
        }

        var desc = new RTCSessionDescription(remote_description);
        var stuff = peer.setRemoteDescription(desc,
            function () {
                console.log("setRemoteDescription succeeded");
                if (remote_description.type == "offer") {
                    console.log("Creating answer");
                    peer.createAnswer(function (local_description) {
                        //console.log("Answer description is: ", local_description);
                        peer.setLocalDescription(local_description,
                            function () {
                                socket.emit('relaySessionDescription', {
                                    'peer_id': peer_id,
                                    'session_description': local_description
                                });
                                console.log("Answer setLocalDescription succeeded");
                            },
                            function () { Alert("Answer setLocalDescription failed!"); }
                        );
                    }, function (error) {
                        console.log("Error creating answer: ", error);
                        console.log(peer);
                    });
                }
            },
            function (error) {
                console.log("setRemoteDescription error: ", error);
            }
        );
        // console.log("Description Object: ", desc);
    });

    /**
     * The offerer will send a number of ICE Candidate blobs to the answerer so they 
     * can begin trying to find the best path to one another on the net.
     */
    socket.on('iceCandidate', function (config) {
        var peer = peers[config.peer_id];
        var ice_candidate = config.ice_candidate;
        if(peer === undefined) {
            console.warn(`[Socket 🔊] Peer [${config.peer_id}] was not found among peers`);
        } else {
            peer.addIceCandidate(new RTCIceCandidate(ice_candidate));
        }
    });

    /**
     * When a user leaves a channel (or is disconnected from the signaling server) everyone will recieve a 'removePeer' message
     * telling them to trash the media channels they have open for that peer.
     * If it was this client that left a channel, they'll also receive the removePeers.
     * If this client was disconnected, they wont receive removePeers,
     * but rather the socket.on('disconnect') code will kick in and tear down all the peer sessions.
     */
    socket.on('removePeer', function (config) {
        console.log('[Socket 🔊] Signaling server said to remove peer:', config);
        var peer_id = config.peer_id;
        if (peer_id in peer_media_elements) {
            peer_media_elements[peer_id].remove();
        }
        if (peer_id in peers) {
            peers[peer_id].close();
        }

        delete peers[peer_id];
        delete peer_media_elements[config.peer_id];
    });
}

/***********************/
/** Local media stuff **/
/***********************/
const setup_local_media = () => {
    return new Promise((resolve, reject) => {

        /* ie, if we've already been initialized */
        if (local_media_stream != null) {
            resolve();
        }

        /* Ask user for permission to use the computers microphone and/or camera, 
         * attach it to an <audio> or <video> tag if they give us access. */
        console.log("[System 🔊] Requesting access to local audio / video inputs");

        navigator.mediaDevices.getUserMedia({ "audio": USE_AUDIO, "video": USE_VIDEO }).then(stream => {
            /* user accepted access to a/v */
            console.log("[System 🔊] Access granted to audio/video");
            MEDIA_ALLOWED = true;
            local_media_stream = stream;
            var local_media = USE_VIDEO ? $("<video>") : $("<audio>");
            local_media.attr("id", "self-audio");
            local_media.attr("autoplay", "autoplay");
            local_media.attr("muted", "true"); /* always mute ourselves by default */
            local_media.attr("controls", "");
            local_media.attr("style", "display:none") // do not display our audio element
            $('body').append(local_media);
            var local_media = document.getElementById("self-audio");
            local_media.volume = 0; // we do not want to hear ourself
            local_media.srcObject = stream;
            resolve();
        }).catch(err => {
            console.log("[System 🔊] Access denied for audio/video : you CANNOT talk to people !", err);
            MEDIA_ALLOWED = false;
        });
    });
}

/**
 * Toggle user microphone
 */
const toggleMicrophone = (value) => {
    if (local_media_stream) {
        local_media_stream.getAudioTracks()[0].enabled = value;
        console.log("[System 🔊] Mic is now " + (value ? "unmuted" : "muted"));
    } else {
        console.log("[System 🔊] You can't toggle your mic if you didn't activate your mic 🤡");
    }
}

const isMediaAllowed = () => {
    return MEDIA_ALLOWED;
}

function presenterAddScreen(stream) {
    console.log("[Socket 🎬] Presenter run a screen share");
    local_screensharing_stream = stream;
    const screen = stream.getTracks()[0];
    socket.emit('addScreenSharing', { "socketid": socket.id, "nickname": socket.playerNickname });
    //add screen share as a new track on existing stream with audio
    local_media_stream.addTrack(screen);

    console.log("local_media_stream : ", local_media_stream);
    console.log("local_media_stream tracks : ", local_media_stream.getTracks());

    for (peer_id in peers) {
        //peers[peer_id].addStream(stream);
        peers[peer_id].getSenders().forEach((sender) => {
            if (sender.track == null) {
                sender.replaceTrack(screen);
            }
            else if (sender.track.kind === "video") {
                sender.replaceTrack(screen);
            }
            else {
                peers[peer_id].addTrack(screen, stream);
            }
        });

        peers[peer_id].createOffer(
            function (local_description) {
                console.log(`[Socket 🎬] Creating screen-share offer for [${peer_id}]`);
                // console.log("Local offer description is: ", local_description);
                peers[peer_id].setLocalDescription(local_description,
                    function () {
                        socket.emit('relaySessionDescription', {
                            'peer_id': peer_id,
                            'session_description': local_description
                        });
                        console.log("Offer setLocalDescription succeeded");
                    },
                    function () { Alert("Offer setLocalDescription failed!"); }
                );
            },
            function (error) {
                console.log("Error sending offer: ", error);
            }
        );
    }
}

/**
 * Player changed room, we need to notify server that he left its previous room, and joined its new one.
 * @param {*} previousRoom : the room he just left
 * @param {*} room : the room he just entered
 * @param {*} nickname : its nickname, for logs
 */
const changeRoom = (previousRoom, room, nickname) => {
    leaveChannel(previousRoom, nickname);
    joinChannel(room, nickname);
};

export const Vocal = {
    init,
    toggleMicrophone,
    isMediaAllowed,
    changeRoom,
    presenterAddScreen  
}